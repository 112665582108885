
import { defineComponent } from 'vue';
import HokIcon from './HokIcon.vue';

export default defineComponent({
	components: { HokIcon },
	data() {
		const color = 'purple';
		const icon = 'icon-alert';
		const isInfoBox = false;

		return {
			isInfoBox,
			icon,
			color
		};
	},
	props: {
		type: { type: String, default: '' }
	}
});
