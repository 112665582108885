import Vue from 'vue'

/* eslint-disable-next-line quotes, comma-spacing */
const API_METHODS = ["VueIntegration","attachErrorHandler","createTracingMixins","init","vueRouterInstrumentation","attachErrorHandler","createTracingMixins","VueIntegration","FunctionToString","Hub","InboundFilters","ModuleMetadata","Scope","addBreadcrumb","addGlobalEventProcessor","addIntegration","addTracingExtensions","captureEvent","captureException","captureMessage","close","configureScope","continueTrace","createTransport","extractTraceparentData","flush","getActiveSpan","getActiveTransaction","getClient","getCurrentHub","getHubFromCarrier","lastEventId","makeMain","makeMultiplexedTransport","setContext","setExtra","setExtras","setMeasurement","setTag","setTags","setUser","spanStatusfromHttpCode","startInactiveSpan","startSpan","startSpanManual","startTransaction","trace","withScope","BrowserClient","makeFetchTransport","makeXHRTransport","defaultStackParser","eventFromException","eventFromMessage","exceptionFromError","createUserFeedbackEnvelope","captureUserFeedback","forceLoad","onLoad","showReportDialog","wrap","Replay","BrowserTracing","instrumentOutgoingRequests","makeBrowserOfflineTransport","onProfilingStartRouteTransaction","BrowserProfilingIntegration","GlobalHandlers","TryCatch","Breadcrumbs","LinkedErrors","HttpContext","Dedupe","init","vueRouterInstrumentation"]
let delayedCalls = []
let SentryMock = {}

let sentryReadyResolve
let loadInitiated = false
let loadCompleted = false

let delayedGlobalErrors = []
let delayedUnhandledRejections = []
/** @param {ErrorEvent} event */
const delayGlobalError = function (event) {
  delayedGlobalErrors.push([event.message, event.filename, event.lineno, event.colno, event.error])
}
const delayUnhandledRejection = function (event) {
  delayedUnhandledRejections.push('reason' in event ? event.reason : 'detail' in event && 'reason' in event.detail ? event.detail.reason : event)
}

const vueErrorHandler = Vue.config.errorHandler

Vue.config.errorHandler = (error, vm, info) => {
  if (!loadCompleted) {
    if (vm) {
      vm.$sentry.captureException(error)
    }

    if (Vue.util) {
      Vue.util.warn(`Error in ${info}: "${error.toString()}"`, vm)
    }
    console.error(error)
  }

  if (vueErrorHandler) {
    return vueErrorHandler(error, vm, info)
  }
}

export default function SentryPlugin (ctx, inject) {
  API_METHODS.forEach((key) => {
    SentryMock[key] = (...args) => delayedCalls.push([key, args])
  })

  window.addEventListener('error', delayGlobalError)
  window.addEventListener('unhandledrejection', delayUnhandledRejection)

  inject('sentry', SentryMock)
  ctx.$sentry = SentryMock

  const loadSentryHook = () => attemptLoadSentry(ctx, inject)

  window.onNuxtReady(loadSentryHook)

  const sentryReadyPromise = new Promise((resolve) => {
    sentryReadyResolve = resolve
  })

  const sentryReady = () => sentryReadyPromise

  inject('sentryReady', sentryReady)
  ctx.$sentryReady = sentryReady
}

async function attemptLoadSentry (ctx, inject) {
  if (loadInitiated) {
    return
  }

  loadInitiated = true

  if (!window.$nuxt) {
    // Wait for onNuxtReady hook to trigger.
    return
  }

  await loadSentry(ctx, inject)
}

async function loadSentry (ctx, inject) {
  if (loadCompleted) {
    return
  }

  const { getConfig, init, SentrySdk } = await import(/* webpackChunkName: 'sentry' */ './sentry.client.shared')

  const config = await getConfig(ctx)
  init({ Vue, ...config })

  loadCompleted = true

  window.removeEventListener('error', delayGlobalError)
  window.removeEventListener('unhandledrejection', delayUnhandledRejection)
  if (delayedGlobalErrors.length) {
    if (window.onerror) {
      console.info('Reposting global errors after Sentry has loaded')
      for (const errorArgs of delayedGlobalErrors) {
        window.onerror.apply(window, errorArgs)
      }
    }
    delayedGlobalErrors = []
  }
  if (delayedUnhandledRejections.length) {
    if (window.onunhandledrejection) {
      console.info('Reposting unhandled promise rejection errors after Sentry has loaded')
      for (const reason of delayedUnhandledRejections) {
        window.onunhandledrejection(reason)
      }
    }
    delayedUnhandledRejections = []
  }
  delayedCalls.forEach(([methodName, args]) => SentrySdk[methodName].apply(SentrySdk, args))

  forceInject(ctx, inject, 'sentry', SentrySdk)
  sentryReadyResolve(SentrySdk)

  // help gc

  // Avoid crashes in case the reference to the mocked object is being used after the actual Sentry instance has loaded.
  API_METHODS.forEach((key) => {
    SentryMock[key] = (...args) => SentrySdk[key].apply(SentrySdk, args)
  })

  // Dont unset delayedCalls & SentryMock during development - this will cause HMR issues.

  delayedCalls = undefined
  SentryMock = undefined

  sentryReadyResolve = undefined
}

// Custom inject function that is able to overwrite previously injected values,
// which original inject doesn't allow to do.
// This method is adapted from the inject method in nuxt/vue-app/template/index.js
function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  window.$nuxt.$options[injectKey] = value
}
