/* eslint-disable */
import { EventEmitter } from 'events';
import Vue from 'vue';
import { getEnvironment } from '@hokify/shared-components/lib/helpers/environmentHelper';

const API_URL = 'https://www.recaptcha.net/recaptcha/api.js';

class ReCaptcha {
	constructor({ hideBadge, language, mode, siteKeyTest, siteKeyProd, version, size }) {
		if (!siteKeyTest || !siteKeyProd) {
			throw new Error('ReCaptcha error: No key provided');
		}

		if (!version) {
			throw new Error('ReCaptcha error: No version provided');
		}

		this._grecaptcha = null;

		this._eventBus = null;

		this.hideBadge = hideBadge;
		this.language = language;

		const environment = getEnvironment();
		this.siteKey = environment === 'production' ? siteKeyProd : siteKeyTest;

		this.version = version;
		this.size = size;

		this.mode = mode;
	}

	async execute(action) {
		try {
			await this.init();

			if ('grecaptcha' in window && this._grecaptcha) {
				return this._grecaptcha.execute(this.siteKey, { action });
			}
		} catch (error) {
			throw new Error(`ReCaptcha error: Failed to execute ${error}`);
		}
		throw new Error(`ReCaptcha error: Not loaded`);
	}

	getResponse(widgetId) {
		return new Promise((resolve, reject) => {
			if ('grecaptcha' in window && this._grecaptcha) {
				if (this.size === 'invisible') {
					this._grecaptcha.execute(widgetId);

					window.recaptchaSuccessCallback = token => {
						this._eventBus.emit('recaptcha-success', token);
						resolve(token);
					};

					window.recaptchaErrorCallback = error => {
						this._eventBus.emit('recaptcha-error', error);
						reject(error);
					};
				} else {
					const response = this._grecaptcha.getResponse(widgetId);

					if (response) {
						this._eventBus.emit('recaptcha-success', response);
						resolve(response);
					} else {
						const errorMessage = 'Failed to execute';

						this._eventBus.emit('recaptcha-error', errorMessage);
						reject(errorMessage);
					}
				}
			}
		});
	}

	init() {
		if (this._ready) {
			// make sure caller waits until recaptcha get ready
			return this._ready;
		}

		this._eventBus = new EventEmitter();

		const script = document.createElement('script');
		const style = document.createElement('style');
		script.setAttribute('async', '');
		script.setAttribute('defer', '');

		const queryParams = new URLSearchParams();
		if (this.version === 3) {
			queryParams.append('render', this.siteKey);
		}
		if (this.language) {
			queryParams.append('hl', this.language);
		}

		let scriptUrl = API_URL;

		if (this.mode === 'enterprise' && !queryParams.has('render')) {
			scriptUrl = scriptUrl.replace('api.js', 'enterprise.js');
			queryParams.append('render', this.siteKey);
		}

		script.setAttribute('src', `${scriptUrl}?${queryParams.toString()}`);

		window.recaptchaSuccessCallback = token => this._eventBus.emit('recaptcha-success', token);
		window.recaptchaExpiredCallback = () => this._eventBus.emit('recaptcha-expired');
		window.recaptchaErrorCallback = () =>
			this._eventBus.emit('recaptcha-error', 'Failed to execute');

		this._ready = new Promise((resolve, reject) => {
			script.addEventListener('load', () => {
				if (this.version === 3 && this.hideBadge) {
					style.innerHTML = '.grecaptcha-badge { display: none }';
					document.head.appendChild(style);
				} else if (this.version === 2 && this.hideBadge) {
					// display: none DISABLES the spam checking!
					// ref: https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge
					style.innerHTML = '.grecaptcha-badge { visibility: hidden; }';
					document.head.appendChild(style);
				}

				this._grecaptcha = window.grecaptcha.enterprise || window.grecaptcha;
				this._grecaptcha.ready(resolve);
			});

			script.addEventListener('error', () => {
				document.head.removeChild(script);
				reject(new Error('ReCaptcha error: Failed to load script'));
				this._ready = undefined;
			});

			document.head.appendChild(script);
		});

		return this._ready;
	}

	on(event, callback) {
		return this._eventBus.on(event, callback);
	}

	reset(widgetId) {
		if (this.version === 2 || typeof widgetId !== 'undefined') {
			this._grecaptcha.reset(widgetId);
		}
	}

	render(reference, { sitekey, theme }) {
		return this._grecaptcha.render(reference.$el || reference, { sitekey, theme });
	}
}

function getModuleOptions(ctx) {
	const { recaptcha = {} } = ctx.$config || {};

	return {
		// sadly this really works that way and no matter what we do, the syntax is not supported by the IDE
		...{"hideBadge":true,"mode":"base","siteKeyTest":"6LcHipcmAAAAAFSBNVjL1AbKpHLiTvPffebokrk4","siteKeyProd":"6Lc141giAAAAALMLiLZmHonh8CfzcWtkT8ZxcwsO","version":3},
		...recaptcha
	};
}

export default function (ctx, inject) {
	const moduleOptions = getModuleOptions(ctx);

	Vue.component('Recaptcha', () => import('./recaptcha.vue'));
	inject('recaptcha', new ReCaptcha(moduleOptions));
}
