import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { ICompanyState } from '~/store/company/state';

export const actions: ActionTree<ICompanyState, IRootState> = {
	async loadTopCompanies(
		{ commit },
		{ page, limit }: { page?: number; limit?: number } = {}
	): Promise<number> {
		const result = await this.$axios.$get('/website-api/companies/list', {
			params: {
				limit,
				page
			}
		});

		commit('updateTopCompanies', result.companies);
		return result.companies?.length || 0;
	},

	async loadOtherCompanies(
		{ commit },
		{ page, limit }: { page?: number; limit?: number } = {}
	): Promise<number> {
		const result = await this.$axios.$get(`/website-api/companies/others`, {
			params: {
				limit,
				page
			}
		});

		commit('updateOtherCompanies', result.companies);
		return result.companies?.length || 0;
	}
};

export default actions;
