import type {
	IAPIArticle,
	IAPIArticleTeaser,
	ArticleOverviewType
} from '@hokify/magazine-interface';
import type { IAPIPageSettings } from '@hokify/common';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IMagazineState } from '~/store/magazine/state';

export const actions: ActionTree<IMagazineState, IRootState> = {
	async loadArticles({ commit }, articleOverviewType: ArticleOverviewType) {
		const result = await this.$axios.$get(
			`/website-api/magazine/articles/overview?articleOverviewType=${articleOverviewType}`
		);
		commit('setArticleTeasers', result.articles);
		return result;
	},

	async loadArticlesForCategory(
		{ commit },
		{ category, page, limit }: { category: string; page?: number; limit?: number }
	) {
		const params: { [key: string]: string | number } = {};
		if (page !== undefined) {
			params.page = page;
		}
		if (limit !== undefined) {
			params.limit = limit;
		}
		const result = await this.$axios.$get(`/website-api/magazine/articles/category/${category}`, {
			params
		});
		commit('updateArticleTeasers', { newArticleTeasers: result, category });
		return result;
	},

	async loadArticle(
		_,
		{ alias, preview }: { alias: string; preview?: boolean }
	): Promise<{
		pageSettings: IAPIPageSettings;
		linkedArticles: IAPIArticleTeaser[];
		article: IAPIArticle;
	}> {
		if (!alias) {
			throw new TypeError('loadArticle: alias is required');
		}

		const params: { [key: string]: string | number } = {
			alias
		};
		if (preview !== undefined) {
			params.preview = String(preview);
		}

		const result = await this.$axios.$get(`/website-api/magazine/article/detail`, {
			params
		});
		return result;
	},

	async loadArticlesByAliases(
		_,
		{ aliases }: { aliases: string[] }
	): Promise<{
		pageSettings: IAPIPageSettings;
		linkedArticles: IAPIArticleTeaser[];
		article: IAPIArticle;
	}> {
		if (!aliases.length) {
			throw new TypeError('loadArticlesByAliases: aliases is required');
		}

		const params: { [key: string]: string[] } = {
			aliases
		};

		const result = await this.$axios.$get(`/website-api/magazine/articles/detail`, {
			params
		});
		return result;
	},

	async loadPressLinks(
		{ commit },
		{ page, limit, sort }: { page?: number; limit?: number; sort?: string } = {}
	): Promise<number> {
		const parameters: { [key: string]: string } = {};

		if (page !== undefined) {
			parameters.page = String(page);
		}

		if (limit !== undefined) {
			parameters.limit = String(limit);
		}

		if (sort !== undefined) {
			parameters.sort = sort;
		}

		const result = await this.$axios.$get(
			`/website-api/magazine/press-links?${
				parameters ? new URLSearchParams(parameters).toString() : ''
			}`
		);
		commit('updatePressLinks', result.pressLinks);
		return result.pressLinks?.length || 0;
	},
	async getDownloadLinks(_, alias: string): Promise<string[]> {
		return this.$axios.$get(`/website-api/magazine/download-link/${alias}`);
	},
	async loadArticlesBySearchterm(_, searchTerm: string) {
		return this.$axios.$get(`/website-api/magazine/articles/search?searchTerm=${searchTerm}`);
	}
};

export default actions;
